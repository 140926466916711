// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["g5irtIpZA", "lbNpeagqi", "vD5imksOD", "Y96WScdxC", "aLBwdYayc", "UX_saRYad", "d7UPZJF_X", "WNiIQESHE", "srnZxUQY7", "tpjgb3DPd"];

const serializationHash = "framer-DIQYo"

const variantClassNames = {aLBwdYayc: "framer-v-1ks9cu6", d7UPZJF_X: "framer-v-ll6yrs", g5irtIpZA: "framer-v-1fyh2r9", lbNpeagqi: "framer-v-jv3nrq", srnZxUQY7: "framer-v-78uzim", tpjgb3DPd: "framer-v-1mgslbf", UX_saRYad: "framer-v-1d5uwzz", vD5imksOD: "framer-v-11tl590", WNiIQESHE: "framer-v-1nn2jgf", Y96WScdxC: "framer-v-21ymmj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, Y96WScdxC: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "UX_saRYad", "Image 16:9": "Y96WScdxC", "Image 3:2": "vD5imksOD", "Image 4:3": "lbNpeagqi", "Image 4:5": "aLBwdYayc", "Variant 10": "tpjgb3DPd", "Variant 7": "d7UPZJF_X", "Variant 8": "WNiIQESHE", "Variant 9": "srnZxUQY7", Default: "g5irtIpZA"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "g5irtIpZA", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "g5irtIpZA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1fyh2r9", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"g5irtIpZA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({aLBwdYayc: {"data-framer-name": "Image 4:5"}, d7UPZJF_X: {"data-framer-name": "Variant 7"}, lbNpeagqi: {"data-framer-name": "Image 4:3"}, srnZxUQY7: {"data-framer-name": "Variant 9"}, tpjgb3DPd: {"data-framer-name": "Variant 10", background: {alt: "", fit: "fill", sizes: "min(350px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}}, UX_saRYad: {"data-framer-name": "Image 1:1"}, vD5imksOD: {"data-framer-name": "Image 3:2"}, WNiIQESHE: {"data-framer-name": "Variant 8"}, Y96WScdxC: {"data-framer-name": "Image 16:9"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-DIQYo[data-border=\"true\"]::after, .framer-DIQYo [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DIQYo.framer-1w5fob4, .framer-DIQYo .framer-1w5fob4 { display: block; }", ".framer-DIQYo.framer-1fyh2r9 { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-DIQYo.framer-v-jv3nrq.framer-1fyh2r9 { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-DIQYo.framer-v-11tl590.framer-1fyh2r9 { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-DIQYo.framer-v-21ymmj.framer-1fyh2r9 { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-DIQYo.framer-v-1ks9cu6.framer-1fyh2r9 { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-DIQYo.framer-v-1d5uwzz.framer-1fyh2r9 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-DIQYo.framer-v-ll6yrs.framer-1fyh2r9 { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-DIQYo.framer-v-1nn2jgf.framer-1fyh2r9 { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-DIQYo.framer-v-78uzim.framer-1fyh2r9 { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }", ".framer-DIQYo.framer-v-1mgslbf.framer-1fyh2r9 { aspect-ratio: 1.09375 / 1; height: var(--framer-aspect-ratio-supported, 320px); width: 350px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"lbNpeagqi":{"layout":["fixed","fixed"]},"vD5imksOD":{"layout":["fixed","fixed"]},"Y96WScdxC":{"layout":["fixed","fixed"]},"aLBwdYayc":{"layout":["fixed","fixed"]},"UX_saRYad":{"layout":["fixed","fixed"]},"d7UPZJF_X":{"layout":["fixed","fixed"]},"WNiIQESHE":{"layout":["fixed","fixed"]},"srnZxUQY7":{"layout":["fixed","fixed"]},"tpjgb3DPd":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerlFh4XrrRL: React.ComponentType<Props> = withCSS(Component, css, "framer-DIQYo") as typeof Component;
export default FramerlFh4XrrRL;

FramerlFh4XrrRL.displayName = "Image / Aspect Ratio Copy 5";

FramerlFh4XrrRL.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerlFh4XrrRL, {variant: {options: ["g5irtIpZA", "lbNpeagqi", "vD5imksOD", "Y96WScdxC", "aLBwdYayc", "UX_saRYad", "d7UPZJF_X", "WNiIQESHE", "srnZxUQY7", "tpjgb3DPd"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9", "Variant 10"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerlFh4XrrRL, [])